import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Section } from '@/components/layout/Section';
import { BlogCards } from '@/components/page/blog/generateBlogCards';

import type { SanityBlogSettingsType } from '@/types/sanity';
import type { BlogPostPreview } from '@/types/shared';

export interface BlogGridProps {
  posts: BlogPostPreview[];
  fallbackImage: SanityBlogSettingsType['featuredImageDefault'];
  fallbackAuthorImage: SanityBlogSettingsType['authorImageDefault'];
}

export const BlogGrid = ({
  posts,
  fallbackImage,
  fallbackAuthorImage,
}: BlogGridProps) => {
  return (
    <Section
      backgroundColour="--background-warm-base"
      verticalPadding
      className="blog-grid"
    >
      <GridContainer>
        <ThreeRowContainer>
          {!!posts[0] && (
            <BlogCards
              post={posts[0]}
              fallbackImage={fallbackImage}
              fallbackAuthorImage={fallbackAuthorImage}
            />
          )}
          {!!posts[1] && (
            <BlogCards
              post={posts[1]}
              fallbackImage={fallbackImage}
              fallbackAuthorImage={fallbackAuthorImage}
            />
          )}
          {!!posts[2] && (
            <BlogCards
              post={posts[2]}
              fallbackImage={fallbackImage}
              fallbackAuthorImage={fallbackAuthorImage}
            />
          )}
        </ThreeRowContainer>
        {!!posts[3] && (
          <TwoRowContainer>
            <BlogCards
              post={posts[3]}
              fallbackImage={fallbackImage}
              fallbackAuthorImage={fallbackAuthorImage}
            />
            {!!posts[4] && (
              <BlogCards
                post={posts[4]}
                fallbackImage={fallbackImage}
                fallbackAuthorImage={fallbackAuthorImage}
              />
            )}
          </TwoRowContainer>
        )}
        {!!posts[5] && (
          <ThreeRowContainer>
            <BlogCards
              post={posts[5]}
              fallbackImage={fallbackImage}
              fallbackAuthorImage={fallbackAuthorImage}
            />
            {!!posts[6] && (
              <BlogCards
                post={posts[6]}
                fallbackImage={fallbackImage}
                fallbackAuthorImage={fallbackAuthorImage}
              />
            )}
            {!!posts[7] && (
              <BlogCards
                post={posts[7]}
                fallbackImage={fallbackImage}
                fallbackAuthorImage={fallbackAuthorImage}
              />
            )}
          </ThreeRowContainer>
        )}
        {!!posts[8] && (
          <TwoRowContainer>
            <BlogCards
              post={posts[8]}
              fallbackImage={fallbackImage}
              fallbackAuthorImage={fallbackAuthorImage}
            />
            {!!posts[9] && (
              <BlogCards
                post={posts[9]}
                fallbackImage={fallbackImage}
                fallbackAuthorImage={fallbackAuthorImage}
              />
            )}
          </TwoRowContainer>
        )}
      </GridContainer>
    </Section>
  );
};

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: var(--spacing-medium);
`;

const ThreeRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-medium);

  ${screen.md} {
    flex-direction: row;

    align-items: inherit;

    article {
      width: 32.5%;
    }
  }
`;

const TwoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-medium);

  ${screen.md} {
    flex-direction: row;
    justify-content: space-between;

    align-items: inherit;

    article {
      width: 49%;
    }
  }
`;
