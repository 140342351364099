import styled from '@emotion/styled';
import Link from 'next/link';

import BaseLink from '@/components/common/BaseLink/BaseLink';
import { screen } from '@/components/common/breakpoints';
import { Image } from '@/components/common/image';
import { Heading, HeadingLevel, Paragraph } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { formatDate, PortableText } from '@/components/common/utils';
import { Stack } from '@/components/layout/Stack';
import { bodyLarge, labelMedium } from '@/styles/typography';

import type { TypographySizeTypes } from '@/styles/typography';
import type { SanityBlogSettingsType } from '@/types/sanity';
import type { BlogPostPreview } from '@/types/shared';

const components = {
  block: ({ value, children }) => {
    switch (value.style) {
      case 'normal':
        return <Paragraph size="body-lg">{children}</Paragraph>;
      case 'h2':
        return <Paragraph size="body-lg">{children}</Paragraph>;
      default:
        return children;
    }
  },
};
export interface BlogCardsProps {
  post: BlogPostPreview;
  fallbackImage: SanityBlogSettingsType['featuredImageDefault'];
  fallbackAuthorImage: SanityBlogSettingsType['authorImageDefault'];
  isFeaturePost?: boolean;
}

const BlogCards = ({
  post,
  fallbackImage,
  fallbackAuthorImage,
  isFeaturePost,
}: BlogCardsProps) => {
  const postImage =
    (post.featuredImage?.asset?._ref && post.featuredImage) ||
    (fallbackImage?.asset?._ref && fallbackImage);

  const authorImage = (post.author as any).image || fallbackAuthorImage;

  return (
    <>
      {!!isFeaturePost && (
        <Row
          className={
            post.trackingTag?.blockName &&
            `tracking-${post.trackingTag.blockName}__${post.trackingTag.blockAction}`
          }
        >
          {!!post && (
            <HeadingLevel>
              <ImageContainerRow>
                {!!postImage?.asset?._ref && (
                  <Image
                    {...postImage}
                    link={`/blog/${post?.slug?.current}`}
                    alt=""
                    isDecorativeImage
                  />
                )}
              </ImageContainerRow>
              <CopyContainer className="featuredCopyContainer">
                <BlogCardContent
                  authorImage={authorImage}
                  post={post}
                  paragraphSize="subheading-sm"
                  headingSize="heading-sm"
                />
              </CopyContainer>
            </HeadingLevel>
          )}
        </Row>
      )}
      {!isFeaturePost && (
        <StyledSurface
          className={
            post.trackingTag?.blockName &&
            `tracking-${post.trackingTag.blockName}__${post.trackingTag.blockAction}`
          }
          as="article"
          padding={false}
          surfaceColour="--surface-subtle"
          borderRadius="--radius-m"
        >
          {!!post && (
            <HeadingLevel>
              <ImageContainer>
                {!!postImage?.asset?._ref && (
                  <Link href={`/blog/${post?.slug?.current}`}>
                    <Image {...postImage} isDecorativeImage />
                  </Link>
                )}
              </ImageContainer>
              <Surface>
                <BlogCardContent
                  authorImage={authorImage}
                  post={post}
                  paragraphSize="body-md"
                  headingSize="heading-sm"
                />
              </Surface>
            </HeadingLevel>
          )}
        </StyledSurface>
      )}
    </>
  );
};

interface BlogCardContentProps {
  post: BlogPostPreview;
  authorImage: { asset: { _ref: string } };
  paragraphSize: TypographySizeTypes;
  headingSize: TypographySizeTypes;
}

const BlogCardContent = ({
  authorImage,
  post,
  paragraphSize,
  headingSize,
}: BlogCardContentProps) => {
  const category = post.category[0];
  const author = post.author;

  return (
    <>
      <StyledStack
        spacing={{
          base: '--spacing-x-small',
          md: '--spacing-xx-small',
        }}
      >
        <CategoryLink
          href={`/blog/category/${category.slug?.current}`}
          aria-label={`View blogs under category ${category.name}`}
        >
          {category.name}
        </CategoryLink>
        <TitleLink href={`/blog/${post?.slug?.current}`}>
          <PostHeading size={headingSize}>{post.title}</PostHeading>
        </TitleLink>

        <TextLink
          href={`/blog/${post?.slug?.current}`}
          className="linkPostExcerpt"
        >
          {!!post.excerpt && (
            <PostBodyText size={paragraphSize}>{post.excerpt}</PostBodyText>
          )}
          {!post.excerpt && !!post.postBody && (
            <PostBodyText size={paragraphSize}>
              <PortableText components={components} value={post.postBody[0]} />
            </PostBodyText>
          )}
        </TextLink>
        <Stack>
          <BaseLineContainer>
            <AuthorImageContainer>
              {!!authorImage?.asset?._ref && (
                <Link
                  aria-label={`Link to ${author?.name} page`}
                  href={`/blog/author/${author?.slug?.current}`}
                >
                  <Image
                    {...authorImage}
                    sizes="50vw"
                    alt={`Image of ${author?.name}`}
                  />
                </Link>
              )}
            </AuthorImageContainer>
            <div>
              <AuthorCaption size="label-sm">By {author?.name}</AuthorCaption>
              <Date size="label-sm">{formatDate(post?.publishDate)}</Date>
            </div>
          </BaseLineContainer>
        </Stack>
      </StyledStack>
    </>
  );
};

const StyledSurface = styled(Surface)`
  display: flex;
  flex-direction: column;
`;

const StyledStack = styled(Stack)`
  height: 100%;
`;

const CategoryLink = styled(BaseLink)`
  ${labelMedium}

  color: var(--text-warm-subtle);
  &:visited {
    color: var(--text-warm-subtle);
  }
`;

const TextLink = styled(BaseLink)`
  ${bodyLarge}
  height: 100%;

  &:hover {
    text-decoration: none;
  }
`;

const TitleLink = styled(BaseLink)`
  text-decoration-color: var(--text-strong);
`;

const PostBodyText = styled(Paragraph)`
  color: var(--text-warm-base);
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  ${screen.md} {
    -webkit-line-clamp: 4;
  }
`;

const PostHeading = styled(Heading)`
  color: var(--text-strong);
`;

const ImageContainer = styled.div`
  display: flex;
  height: 236px;
  width: calc(100% + 1px);
  flex-direction: column;
  border-radius: var(--radius-m) var(--radius-m) 0 0;
  img {
    object-fit: cover;
    border-radius: var(--radius-m) var(--radius-m) 0 0;
    bottom: unset;
    max-height: unset;
    height: 100%;
  }
  a {
    height: 100%;
  }
`;

const BaseLineContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: var(--spacing-large);
  gap: var(--spacing-xx-small);
`;

const AuthorImageContainer = styled.div`
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  a {
    display: block;
  }
`;

const AuthorCaption = styled(Paragraph)`
  margin-bottom: 2px;
  color: var(--text-warm-base);
`;

const Date = styled(Paragraph)`
  color: var(--text-warm-subtle);
  font-weight: var(--font-weight-regular);
`;

// Legacy FeaturedPost Styling:

const Row = styled.article`
  border-radius: var(--radius-m);
  background-color: var(--surface-subtle);
  transition: all 0.3s;
  overflow: hidden;
  max-width: 600px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  p {
    color: var(--text-warm-base);
  }
  ${screen.md} {
    max-width: unset;
    margin-bottom: 0px;
    flex-direction: unset;
    .featuredCopyContainer {
      height: 100%;
    }
    &:hover {
      transform: scale(1.03);
    }
  }
`;

const ImageContainerRow = styled.div`
  display: flex;
  width: calc(100% + 1px);
  flex-direction: column;
  border-radius: calc(var(--radius-s) / 2) calc(var(--radius-s) / 2) 0 0;
  img {
    object-fit: cover;
    border-radius: calc(var(--radius-s) / 2) calc(var(--radius-s) / 2) 0 0;
    bottom: unset !important;
    max-height: unset !important;
    height: 100%;
  }

  ${screen.md} {
    width: 68%;
    min-width: 68%;
    height: unset !important;
    border-radius: calc(var(--radius-s) / 2) 0 0 calc(var(--radius-s) / 2);
    img {
      object-fit: cover;
      border-radius: calc(var(--radius-s) / 2) 0 0 calc(var(--radius-s) / 2);
      bottom: unset !important;
      max-height: unset !important;
      height: auto !important;
      height: 464px;
    }
  }
`;
const CopyContainer = styled.div`
  padding: var(--spacing-large);
  min-height: 220px;
  height: calc(100% - 236px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;

  p:first-of-type {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  p {
    -webkit-line-clamp: 3;
  }
  ${screen.md} {
    p {
      -webkit-line-clamp: 4;
    }
  }
`;

export { BlogCards };
