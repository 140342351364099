import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence, m } from 'framer-motion';
import { useRouter } from 'next/router';

import { screen } from '@/components/common/breakpoints';
import { IconOnlyButton } from '@/components/common/Buttons';
import { Paragraph } from '@/components/common/MarkUp';
import { Section } from '@/components/layout/Section';
import { AuthorDetails } from '@/components/page/blog/AuthorDetails';
import { BlogFilters as Filters } from '@/components/page/blog/BlogFilters';
import { BlogGrid } from '@/components/page/blog/BlogGrid';
import { BlogCards } from '@/components/page/blog/generateBlogCards';
import { ListingsHero } from '@/components/page/blog/ListingsHero';
import { Pagination } from '@/components/page/blog/Pagination';
import { NoResults } from '@/components/page/careers/NoResults';
import { subheadingSmall } from '@/styles/typography';

import type {
  SanityBlock,
  SanityBlogAuthorType,
  SanityBlogCategoryType,
  SanityBlogSettingsType,
} from '@/types/sanity';
import type { BlogPostPreview } from '@/types/shared';
import type { Variants } from 'framer-motion';
import type { FormEvent } from 'react';

export interface BlogCatalogueProps {
  blogSettings: SanityBlogSettingsType;
  categories: SanityBlogCategoryType[];
  posts: BlogPostPreview[];
  pageCount: number;
  postCount: number;
  pageType: string;
  pageSubType: string | null;
  author: SanityBlogAuthorType | undefined;
}

interface SanityBlockWithKey extends SanityBlock {
  _key: string;
}
interface FormElements extends HTMLFormControlsCollection {
  search: HTMLInputElement;
}
interface SearchFormElements extends HTMLFormElement {
  readonly elements: FormElements;
}

export const BlogCatalogue = ({
  blogSettings,
  categories,
  posts,
  pageCount,
  postCount,
  pageType,
  pageSubType,
  author,
}: BlogCatalogueProps) => {
  const {
    heading,
    featuredPost,
    isFeaturedPost,
    featuredImageDefault,
    authorImageDefault,
  } = blogSettings;

  const [showSearch, setShowState] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const router = useRouter();

  const { query, asPath } = router;
  const page = Number(query.page ?? 1);

  const showFeaturedPost =
    page === 1 &&
    featuredPost !== undefined &&
    !!isFeaturedPost &&
    pageType !== 'author';

  let pageHeading: SanityBlockWithKey[] = heading;

  if (query.search) {
    pageHeading = generateSearchHeading();
  } else if (pageType === 'category') {
    const category = categories.find((c) => c.slug.current === pageSubType);
    const title = category?.name;
    pageHeading = generateCategoryHeading(title);
  } else if (pageType === 'author') {
    pageHeading = generateAuthorHeading(author[0].name);
  }

  const searchSubmit = (e?: FormEvent<SearchFormElements>) => {
    e?.preventDefault();

    router.push({
      pathname: asPath.split('?')[0],
      query: { search: e.currentTarget.elements.search.value },
    });
  };

  return (
    <>
      <ListingsHero key={pageType + query.search} heading={pageHeading} />

      <Section
        backgroundColour="--background-warm-base"
        verticalPadding="leading"
        className="blog-catalogue"
      >
        <CategoryContainer ref={containerRef}>
          <Filters label="Filter by blog category" options={categories} />

          <IconOnlyButton
            variant="solid-light"
            size="md"
            onClick={() => setShowState(!showSearch)}
            aria-label="Search blogs"
            icon="Search"
            style={{ visibility: showSearch ? 'hidden' : 'visible' }}
          />
        </CategoryContainer>

        <AnimatePresence>
          {showSearch && (
            <SearchContainer
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
              transition={{
                delay: 0,
                duration: 0.4,
                opacity: { duration: 0.4 },
                ease: 'easeInOut',
              }}
            >
              <form id="searchForm" onSubmit={searchSubmit}>
                <SearchInput
                  type="text"
                  placeholder="Search..."
                  name="search"
                  id="search"
                  aria-label="Search Blog Posts"
                />
                <IconOnlyButton
                  type="submit"
                  aria-label="Search"
                  icon="Search"
                  variant="solid-dark"
                  size="md"
                />
              </form>

              <IconOnlyButton
                variant="solid-light"
                size="md"
                onClick={() => setShowState(false)}
                aria-label="Clear search box"
                icon="Close"
              />
            </SearchContainer>
          )}
        </AnimatePresence>

        {query.search && postCount > 0 && (
          <Results size="body-lg" className="searchResultsText">
            {postCount} results for &apos;{query.search}&apos;
          </Results>
        )}
        {query.search && postCount === 0 && <NoResults />}
      </Section>
      {author?.[0] && <AuthorDetails author={author?.[0]} />}
      <>
        {posts?.length >= 1 && (
          <>
            {!!showFeaturedPost && (
              <FeaturedContainer>
                <BlogCards
                  isFeaturePost={showFeaturedPost}
                  post={featuredPost as any}
                  fallbackImage={featuredImageDefault}
                  fallbackAuthorImage={authorImageDefault}
                />
              </FeaturedContainer>
            )}

            <BlogGrid
              posts={posts}
              fallbackImage={featuredImageDefault}
              fallbackAuthorImage={authorImageDefault}
            />

            <Pagination pageCount={pageCount} />
          </>
        )}
      </>
    </>
  );
};

const variants: Variants = {
  visible: {
    height: 'auto',
    opacity: 1,
    marginTop: 'var(--spacing-medium)',
    visibility: 'visible',
  },
  hidden: {
    height: 0,
    opacity: 0,
    marginTop: 0,

    transitionEnd: { visibility: 'hidden' },
  },
};

const FeaturedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: var(--spacing-300) 0 var(--spacing-500);

  ${screen.md} {
    margin: var(--spacing-300) 0 var(--spacing-500);
  }
`;

const CategoryContainer = styled.div`
  display: flex;

  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xx-small) var(--spacing-x-small);

  ${screen.md} {
    flex-direction: row;
    justify-content: center;
  }
`;

const Results = styled(Paragraph)`
  margin-top: var(--spacing-small);

  text-align: center;
`;

const SearchContainer = styled(m.div)`
  margin: var(--spacing-medium) auto 0;

  display: flex;
  gap: var(--spacing-small);

  max-width: 480px;
  position: relative;

  form {
    position: relative;
    flex: 1;

    button {
      position: absolute;

      top: 5px;
      right: 3px;

      ${screen.md} {
        top: 0;
        right: 0;

        transform: scale(0.8);
      }
    }
  }
`;

const SearchInput = styled.input`
  ${subheadingSmall}
  width: 100%;
  border: 1px solid var(--border-action);
  line-height: 40px;
  color: var(--text-action);
  text-indent: 20px;
  border-radius: var(--radius-m);
`;

const generateCategoryHeading = (title: string): SanityBlockWithKey[] => {
  const categoryHeading: SanityBlockWithKey[] = [
    {
      _key: 'e0ab5aa7e3eb',
      _type: 'block',
      children: [
        {
          _key: 'e0ab5aa7e3eb',
          _type: 'span',
          marks: [],
          text: title,
        },
      ],
      style: 'normal',
    },
  ];

  return categoryHeading;
};

const generateSearchHeading = (): SanityBlockWithKey[] => [
  {
    _key: 'e0ab5aa7e3eb',
    _type: 'block',
    children: [
      {
        _key: 'e0ab5aa7e3eb',
        _type: 'span',
        marks: [],
        text: 'Search results',
      },
    ],
    style: 'normal',
  },
];

const generateAuthorHeading = (name: string): SanityBlockWithKey[] => {
  const authorHeading: SanityBlockWithKey[] = [
    {
      _key: 'e0ab5aa7e3eb',
      _type: 'block',
      children: [
        {
          _key: 'e0ab5aa7e3eb',
          _type: 'span',
          marks: [],
          text: `Opinions from ${name}`,
        },
      ],
      style: 'normal',
    },
  ];

  return authorHeading;
};
