import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { RichHeading } from '@/components/common/Heading';
import { HeadingLevelProvider } from '@/components/common/Heading/context';
import { Section } from '@/components/layout/Section';
import { useSiteSettings } from '@/lib/useSiteSettings';

import type { HeadingSanityBlockType } from '@/components/common/Heading';
import type { BackgroundColourTokenType } from '@/types/colours';

export interface ListingsHeroProps {
  heading?: HeadingSanityBlockType;
  backgroundColour?: BackgroundColourTokenType;
}

export const ListingsHero = ({
  heading,
  backgroundColour = '--background-primary',
}: ListingsHeroProps) => {
  const siteSettings = useSiteSettings();
  const newNavigation = siteSettings?.newNavigation;

  return (
    <Section
      backgroundColour={backgroundColour}
      verticalPadding="hero"
      className="listings-hero"
      newNavigation={newNavigation}
    >
      <HeroContainer>
        <HeadingLevelProvider level={1}>
          <CopyContainer>
            <RichHeading size="display-md" heading={heading} />
          </CopyContainer>
        </HeadingLevelProvider>
      </HeroContainer>
    </Section>
  );
};

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xxx-large) 0;

  ${screen.md} {
    flex-direction: row;
  }

  color: var(--text-inverse-strong);
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 var(--layout-gutter);
  text-align: center;

  ${screen.md} {
    padding: 0;
    text-align: center;
  }

  h1 span span:not(:only-of-type) {
    height: 0;
    :first-of-type {
      svg {
        height: 0;
      }
    }
  }
`;
